import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../assets/images/logo.webp';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (e, target) => {
    e.preventDefault();
    setIsMenuOpen(false);
    const element = document.getElementById(target);
    if (element) {
      const offsetTop = element.offsetTop;
      scroll.scrollTo(offsetTop, {
        duration: 500,
        smooth: true,
      });
    }
  };

  return (
    <header className="bg-black w-full p-4 fixed top-0 left-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-auto h-10" loading="lazy"/>
        </div>
        <button
          className="text-white lg:hidden cursor-pointer"
          onClick={toggleMenu}
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
            <path d="M4 5L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 12L20 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 19L20 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <nav
          className={`lg:flex lg:space-x-4 text-white ${isMenuOpen ? 'fixed inset-0 bg-black bg-opacity-100 flex flex-col items-start p-4 z-40 transition-transform transform translate-x-0 w-1/2' : 'fixed inset-0 bg-black bg-opacity-100 flex flex-col items-start p-4 transition-transform transform -translate-x-full w-1/2'} lg:bg-transparent lg:relative lg:inset-auto lg:p-0 lg:translate-x-0 lg:w-auto`}
        >
          <div className="w-full flex justify-end lg:hidden">
            <button
              className="text-white cursor-pointer"
              onClick={toggleMenu}
              aria-label="Close menu"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
                <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
              </svg>
            </button>
          </div>
          <ul className="flex flex-col lg:flex-row lg:space-x-4 text-white w-full space-y-4 lg:space-y-0">
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#home" onClick={(e) => handleLinkClick(e, 'home')}>Home</a>
            </li>
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#my-story" onClick={(e) => handleLinkClick(e, 'my-story')}>My Story</a>
            </li>
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#education" onClick={(e) => handleLinkClick(e, 'education')}>Education</a>
            </li>
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#skill" onClick={(e) => handleLinkClick(e, 'skill')}>Skill</a>
            </li>
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#portfolio" onClick={(e) => handleLinkClick(e, 'portfolio')}>Portfolio</a>
            </li>
            <li className="uppercase text-sm transition-colors duration-300 ease-in-out hover:text-picton-blue-500 cursor-pointer">
              <a href="#contact-me" onClick={(e) => handleLinkClick(e, 'contact-me')}>Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
