import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="w-full bg-black">
      <footer className="text-gray-400 py-6">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="text-center sm:text-left mb-4 sm:mb-0">
              © {currentYear}, Aafith. All Rights Reserved.
            </p>
            <div className="flex space-x-6">
              <a href="https://github.com/aafith" className="hover:text-picton-blue-500">
                GitHub
              </a>
              <a href="https://linkedin.com/aafith" className="hover:text-picton-blue-500">
                LinkedIn
              </a>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={scrollToTop}
              aria-label="Scroll to top"
              className="bg-picton-blue-500 p-2 rounded-full text-black flex items-center justify-center"

            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 11.5C18 11.5 13.5811 5.5 12 5.5C10.4188 5.5 6 11.5 6 11.5" />
                <path d="M18 18.5C18 18.5 13.5811 12.5 12 12.5C10.4188 12.5 6 18.5 6 18.5" />
              </svg>
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
