import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import MyStory from './components/MyStory';
import Education from './components/Education';
import Skill from './components/Skill';
import Portfolio from './components/Portfolio';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <Header />
      <Home />
      <MyStory />
      <Education />
      <Skill />
      <Portfolio />
      <ContactMe />
      <Footer />
    </div>
  );
}

export default App;
