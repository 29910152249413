import React from 'react';
import Kingston from '../assets/images/kingston-university-london.webp';
import Esoft from '../assets/images/esoft.webp';


const Education = () => {
  return (
    <div id="education" className="py-6 bg-black text-white w-full flex flex-col items-center">
      {/* Education Section */}
      <div className="max-w-4xl">
        <h2 className="text-gray-400 text-sm mb-2 uppercase flex items-center justify-center">Education</h2>
        <div className="w-full max-w-4xl mt-12">
          <div className="education-entry flex flex-col md:flex-row items-center mb-6">
            <img src={Kingston} alt="Kingston" className="w-24 h-24 mb-4 md:mb-0 md:mr-6 object-cover" loading="lazy"/>
            <div className="education-details text-center md:text-left">
              <h3 className="text-xl font-semibold">BSc (Hons) in Computer Science</h3>
              <p className="text-gray-400">Kingston University, London, UK</p>
            </div>
          </div>
          <div className="education-entry flex flex-col md:flex-row items-center mb-6">
            <img src={Esoft} alt="ESoft" className="w-24 h-24 mb-4 md:mb-0 md:mr-6 object-cover" loading="lazy"/>
            <div className="education-details text-center md:text-left">
              <h3 className="text-xl font-semibold">HND in Software Engineering</h3>
              <p className="text-gray-400">ESoft Metro Campus, Sri Lanka</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
