import React, { useEffect } from "react";
import profilePic from '../assets/images/profile-pic.webp';

const Home = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = profilePic;
    link.as = 'image';
    link.type = 'image/webp';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleDownload = () => {
    window.location.href = 'https://drive.google.com/file/d/16qnyOOfk_sx6ENNlzEq0oIHXGFIHPDKB/view?usp=sharing';
  };

  const scrollToContact = () => {
    const contactSection = document.querySelector('#contact-me');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const yearsCount = currentYear - startYear;

  return (
    <div id="home" className="w-full min-h-screen bg-black pt-16 pb-10 text-white flex items-center justify-center">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-center md:justify-between space-y-10 md:space-y-0 md:space-x-44">
        {/* Left Section */}
        <div className="md:w-1/2 text-center md:text-left">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-4 leading-tight">
            Hello,
          </h1>
          <h1 className="text-picton-blue-500 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight">
            <span className="text-white">I'm</span> Mohamed Aafith
          </h1>
          <h2 className="text-gray-400 text-md sm:text-base md:text-lg lg:text-xl mb-6">
            Freelancer specializing in Web Development & Digital Marketing, BSc in Computer Science
          </h2>
          <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-3 sm:space-y-0 sm:space-x-4">
            <button
              onClick={scrollToContact}
              className="bg-picton-blue-500 text-black px-4 py-2 rounded-full font-semibold transform transition-transform duration-300 ease-in-out hover:scale-105 z-30"
              aria-label="Scroll to Contact Section"
            >
              Hire Me
            </button>
            <button
              className="border justify-center border-picton-blue-500 text-picton-blue-500 px-4 py-2 rounded-full font-semibold flex items-center space-x-2 transform transition-transform duration-300 ease-in-out hover:scale-105 z-30"
              onClick={handleDownload}
              aria-label="Download CV"
            >
              <span>Download CV</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" className="ml-2">
                <path d="M18.25 8.99997C20.3077 9.07357 22.0549 10.6169 21.9987 12.6843C21.9856 13.1653 21.7993 13.7598 21.4266 14.9488C20.5298 17.8104 19.0226 20.2944 15.6462 20.8904C15.0255 21 14.3271 21 12.9303 21H11.0697C9.6729 21 8.9745 21 8.35384 20.8904C4.97739 20.2944 3.47018 17.8104 2.57336 14.9488C2.20072 13.7598 2.01439 13.1653 2.00132 12.6843C1.94512 10.6169 3.6923 9.07357 5.75001 8.99997" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M12 14L12 3M12 14C11.2998 14 9.99153 12.0057 9.5 11.5M12 14C12.7002 14 14.0085 12.0057 14.5 11.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:w-1/2 flex flex-col items-center md:items-center space-y-8">
          <img
            src={profilePic}
            alt="Mohamed Aafith"
            className="rounded-3xl w-48 h-48 sm:w-60 sm:h-60 md:w-72 md:h-72 object-cover"
            loading="lazy"
          />
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-picton-blue-500 rounded-3xl">
            <div className="text-center">
              <div className="text-2xl text-black sm:text-3xl font-bold">{yearsCount}+</div>
              <div className="text-black text-sm sm:text-base">Years Of Experience</div>
            </div>
            <div className="text-center">
              <div className="text-2xl text-black sm:text-3xl font-bold">10</div>
              <div className="text-black text-sm sm:text-base">Projects Completed</div>
            </div>
            <div className="text-center">
              <div className="text-2xl text-black sm:text-3xl font-bold">100%</div>
              <div className="text-black text-sm sm:text-base">Client Satisfaction</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
