import React from 'react';
import profilePic from '../assets/images/profile-pic.webp';

const MyStory = () => {
  return (
    <section className="bg-white py-10 px-5 md:px-20 lg:px-40 text-white" id="my-story">
      <div className="flex flex-col lg:flex-row bg-black p-8 items-center lg:items-start rounded-3xl">
        <div className="flex-1 lg:mr-10">
          <h2 className="text-gray-400 text-sm mb-2 uppercase">My Story</h2>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Freelancer <span className="text-picton-blue-500">specializing in Web Development & Digital Marketing,</span> BSc in Computer Science
          </h1>
          <p className="text-gray-400 mb-6">
            My name is Mohamed Afnan Aafith. I have completed an HND in Software Engineering and am currently pursuing a BSc (Hons) in Computer Science with a specialization in Software Engineering at Kingston University, London, UK. I have gained 6 months of professional experience working as a graphic designer at Ideageek and am now freelancing on Upwork.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            {[
              'Branding & Design',
              'Web Development',
              'Digital Marketing',
              'Product Design'
            ].map((item, index) => (
              <div className="flex items-center" key={index}>
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#0ea5e9" stroke="#0ea5e9" strokeWidth="1.5">
                  <path d="M18.9905 19C18.3678 19.6175 17.2393 19.4637 16.4479 19.4637C15.4765 19.4637 15.0087 19.6537 14.3154 20.347C13.7251 20.9374 12.9337 22 12 22C11.0663 22 10.2749 20.9374 9.68457 20.347C8.99128 19.6537 8.52349 19.4637 7.55206 19.4637C6.76068 19.4637 5.63218 19.6175 5.00949 19C4.38181 18.3776 4.53628 17.2444 4.53628 16.4479C4.53628 15.4414 4.31616 14.9786 3.59938 14.2618C2.53314 13.1956 2.00002 12.6624 2 12C2.00001 11.3375 2.53312 10.8044 3.59935 9.73817C4.2392 9.09832 4.53628 8.46428 4.53628 7.55206C4.53628 6.76065 4.38249 5.63214 5 5.00944C5.62243 4.38178 6.7556 4.53626 7.55208 4.53626C8.46427 4.53626 9.09832 4.2392 9.73815 3.59937C10.8044 2.53312 11.3375 2 12 2C12.6625 2 13.1956 2.53312 14.2618 3.59937C14.9015 4.23907 15.5355 4.53626 16.4479 4.53626C17.2393 4.53626 18.3679 4.38247 18.9906 5C19.6182 5.62243 19.4637 6.75559 19.4637 7.55206C19.4637 8.55858 19.6839 9.02137 20.4006 9.73817C21.4669 10.8044 22 11.3375 22 12C22 12.6624 21.4669 13.1956 20.4006 14.2618C19.6838 14.9786 19.4637 15.4414 19.4637 16.4479C19.4637 17.2444 19.6182 18.3776 18.9905 19Z" />
                  <path d="M9 12.8929C9 12.8929 10.2 13.5447 10.8 14.5C10.8 14.5 12.6 10.75 15 9.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className="flex-1 mt-10 lg:mt-0 lg:ml-10">
          <div className="relative">
            <img
              src={profilePic}
              alt="Profile of Mohamed Afnan Aafith"
              className="w-full rounded-3xl shadow-lg"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center mt-6 space-y-4 lg:space-y-0 lg:space-x-6">
        <a href="mailto:itzaffu1234@gmail.com" className="flex items-center text-black">
          <div className="w-10 h-10 bg-picton-blue-500 text-black rounded-full flex justify-center items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
              <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
            </svg>
          </div>
          <span>Email: itzaffu1234@gmail.com</span>
        </a>

        <a href="https://www.linkedin.com/in/aafith" target="_blank" rel="noreferrer" className="flex items-center text-black">
          <div className="w-10 h-10 bg-picton-blue-500 text-black rounded-full flex justify-center items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
              <path d="M7 10V17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 13V17M11 13C11 11.3431 12.3431 10 14 10C15.6569 10 17 11.3431 17 13V17M11 13V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.00801 7L6.99902 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
            </svg>
          </div>
          <span>LinkedIn</span>
        </a>
      </div>
    </section>
  );
};

export default MyStory;
