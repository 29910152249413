import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactMe() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs.send('service_8plb2qc', 'template_ymzdrjk', formData, 'pyqMyKVUpWT3oTqFk')
      .then((response) => {
        setStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        setTimeout(() => setStatus(''), 5000); // Clear status message after 5 seconds
      }, (error) => {
        setStatus('Message sending failed.');
        setTimeout(() => setStatus(''), 5000); // Clear status message after 5 seconds
      });
  };

  return (
    <div className="min-h-screen text-gray-400 flex justify-center items-center p-6" id='contact-me'>
      <div className="max-w-4xl w-full grid grid-cols-1 md:grid-cols-2 gap-8 bg-black p-8 rounded-3xl">
        <div className="flex flex-col justify-center">
          <h2 className="text-gray-400 text-sm mb-2 uppercase">Get Contact Me</h2>
          <h2 className="text-4xl text-white font-bold mb-4">
            Let’s Talk For Your <span className="text-picton-blue-500">Next Projects</span>
          </h2>
          <p className="mb-8">Have questions? Get in touch via email or phone. We're here to help!</p>
          <div className="grid grid-cols-1 text-white md:grid-cols-1 gap-4 mb-6">
            {[
              `${new Date().getFullYear() - 2022}+ Years Of Experience`,
              'Professional Web Designer',
              'Mobile Apps Design',
              'Custom Design Support'
            ].map((item, index) => (
              <div className="flex items-center" key={index}>
                <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#0ea5e9">
                  <path d="M18.9905 19H19M18.9905 19C18.3678 19.6175 17.2393 19.4637 16.4479 19.4637C15.4765 19.4637 15.0087 19.6537 14.3154 20.347C13.7251 20.9374 12.9337 22 12 22C11.0663 22 10.2749 20.9374 9.68457 20.347C8.99128 19.6537 8.52349 19.4637 7.55206 19.4637C6.76068 19.4637 5.63218 19.6175 5.00949 19C4.38181 18.3776 4.53628 17.2444 4.53628 16.4479C4.53628 15.4414 4.31616 14.9786 3.59938 14.2618C2.53314 13.1956 2.00002 12.6624 2 12C2.00001 11.3375 2.53312 10.8044 3.59935 9.73817C4.2392 9.09832 4.53628 8.46428 4.53628 7.55206C4.53628 6.76065 4.38249 5.63214 5 5.00944C5.62243 4.38178 6.7556 4.53626 7.55208 4.53626C8.46427 4.53626 9.09832 4.2392 9.73815 3.59937C10.8044 2.53312 11.3375 2 12 2C12.6625 2 13.1956 2.53312 14.2618 3.59937C14.9015 4.23907 15.5355 4.53626 16.4479 4.53626C17.2393 4.53626 18.3679 4.38247 18.9906 5C19.6182 5.62243 19.4637 6.75559 19.4637 7.55206C19.4637 8.55858 19.6839 9.02137 20.4006 9.73817C21.4669 10.8044 22 11.3375 22 12C22 12.6624 21.4669 13.1956 20.4006 14.2618C19.6838 14.9786 19.4637 15.4414 19.4637 16.4479C19.4637 17.2444 19.6182 18.3776 18.9905 19Z" stroke="#0ea5e9" strokeWidth="1.5" />
                  <path d="M9 12.8929C9 12.8929 10.2 13.5447 10.8 14.5C10.8 14.5 12.6 10.75 15 9.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {item}
              </div>
            ))}
          </div>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="mb-1 text-white" htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="p-3 rounded-md bg-picton-blue-950 text-white border border-gray-700 focus:outline-none focus:border-picton-blue-500"
                placeholder="AM. Mohamed"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col">
              <label className="mb-1 text-white" htmlFor="email">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                className="p-3 rounded-md bg-picton-blue-950 text-white border border-gray-700 focus:outline-none focus:border-picton-blue-500"
                placeholder="example@mail.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label className="mb-1 text-white" htmlFor="subject">Subject</label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="p-3 rounded-md bg-picton-blue-950 text-white border border-gray-700 focus:outline-none focus:border-picton-blue-500"
              placeholder="I need this to be discussed"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col">
            <label className="mb-1 text-white" htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              className="p-3 rounded-md bg-picton-blue-950 text-white border border-gray-700 focus:outline-none focus:border-picton-blue-500"
              rows="4"
              placeholder="Write message..."
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-picton-blue-500 text-black font-bold rounded-full transform transition-transform duration-300 ease-in-out hover:scale-105 z-30 flex items-center justify-center space-x-2"
          >
            <span>Send Us Message</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>

          {status && <p className="text-green-500 mt-4">{status}</p>}
          
        </form>
      </div>
    </div>
  );
}

export default ContactMe;
