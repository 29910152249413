import React from 'react';
import QBuy from '../assets/images/qbuy.webp';

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      image: QBuy,
      title: 'QBuy E-Commerce Application',
      category: 'React Native',
      description: 'Q-Buy is a React Native app that personalizes e-commerce with tailored product recommendations and location-based deals. It uses Firebase, Firestore, and Expo Location API for a seamless, dynamic shopping experience.',
      githubLink: '',
    },
  ];

  return (
    <section className="text-white py-6 px-4" id="portfolio">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-center text-sm text-black mb-4 uppercase">Latest Works</h2>
        <h1 className="text-center text-4xl font-bold mb-12 text-black">
          Explore My Popular <span className="text-black">Projects</span>
        </h1>
        <div className="space-y-8">
          {projects.map((project, index) => (
            <div
              key={project.id}
              className={`relative group flex flex-col md:flex-row ${index % 2 !== 0 ? 'md:flex-row-reverse' : ''} rounded-3xl overflow-hidden`}
            >
              <div className="md:w-1/2">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-auto object-cover"
                  loading="lazy"
                />
              </div>
              <div className="md:w-1/2 p-6 flex flex-col justify-center bg-black">
                <p className="text-picton-blue-500 text-sm font-semibold">{project.category}</p>
                <h2 className="text-2xl font-bold mt-2">{project.title}</h2>
                <p className="text-gray-400 mt-4">{project.description}</p>
                {project.githubLink && ( // Render GitHub link only if available
                  <a
                    href={project.githubLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute bottom-6 left-1/2 transform -translate-x-1/2 bg-picton-blue-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center gap-2 transition-transform duration-300 ease-in-out scale-0 group-hover:scale-100"
                    style={{ width: '30%' }}
                    aria-label={`View ${project.title} on GitHub`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="none"
                      className="text-white"
                    >
                      <path
                        d="M6.51734 17.1132C6.91177 17.6905 8.10883 18.9228 9.74168 19.2333M9.86428 22C8.83582 21.8306 2 19.6057 2 12.0926C2 5.06329 8.0019 2 12.0008 2C15.9996 2 22 5.06329 22 12.0926C22 19.6057 15.1642 21.8306 14.1357 22C14.1357 22 13.9267 18.5826 14.0487 17.9969C14.1706 17.4113 13.7552 16.4688 13.7552 16.4688C14.7262 16.1055 16.2043 15.5847 16.7001 14.1874C17.0848 13.1032 17.3268 11.5288 16.2508 10.0489C16.2508 10.0489 16.5318 7.65809 15.9996 7.56548C15.4675 7.47287 13.8998 8.51192 13.8998 8.51192C13.4432 8.38248 12.4243 8.13476 12.0018 8.17939C11.5792 8.13476 10.5568 8.38248 10.1002 8.51192C10.1002 8.51192 8.53249 7.47287 8.00036 7.56548C7.46823 7.65809 7.74917 10.0489 7.74917 10.0489C6.67316 11.5288 6.91516 13.1032 7.2999 14.1874C7.79575 15.5847 9.27384 16.1055 10.2448 16.4688C10.2448 16.4688 9.82944 17.4113 9.95135 17.9969C10.0733 18.5826 9.86428 22 9.86428 22Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>GitHub</span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
